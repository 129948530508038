import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CloseIcon from '@mui/icons-material/Close';
const DictionaryDialog = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                variant="rounded"
                onClick={handleOpen}
                color="primary"
                aria-label="open dictionary"
            >
                <AutoStoriesIcon />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '32px',
                    },
                }}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Dictionary</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <iframe
                        src={process.env.REACT_APP_BUDG_LINK + "/Dictionary?isIframe=true"}
                        title="Dictionary"
                        style={{
                            width: '100%',
                            height: '70vh',
                            border: 'none',
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DictionaryDialog; 
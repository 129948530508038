import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import isAuthenticated from "./components/auth/auth";
import AOS from "aos";

// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

//auth componentss
// import GotCode from './components/auth/GotCode';
import CleverAuth from "./components/auth/CleverAuth";
import FullRegister from "./components/auth/FullRegister";
import EmailVerification from "./components/auth/EmailVerification";
// import CodeRegister from './components/auth/CodeRegsiter'
// import RequestCode from './components/auth/RequestCode'
import ResetPassword from "./components/auth/ResetPassword";
import Login from "./components/auth/Login";
import "./index.css"
//Home Screens
// import Module from './components/home/Module'
//Profile components
import Profile from "./components/profile/Profile";

//splash screens
import GetStarted from "./components/splash-screen/GetStarted";
import Wellcome from "./components/splash-screen/Wellcome";
import LearnMore from "./components/splash-screen/LearnMore";

//Other
import HelpDesk from "./components/other/HelpDesk";
import TurnPhoneV from "./components/other/TurnPhoneV";
import TurnPhoneH from "./components/other/TurnPhoneH";
import PrivacyPolicy from "./components/other/PrivacyPolicy";
import SuccessSkillsIntro from "./components/other/SuccessSkillIntro";
import SuccessSkillForm from "./components/other/SuccessSkillForm";
import SuccessSkillSearch from "./components/other/SuccessSkillSearch";
import SuccessSkillList from "./components/other/SuccessSkillList";
import SuccessSkillDetails from "./components/other/SuccessSkillDetails";
import Error from "./components/other/Error";
import CustomerSupport from "./components/other/CustomerSupport";
import NotFound from "./components/other/404";

//modules
import BusinessFoundation from "./components/modules/BusinessFoundation";
import Modules from "./components/modules/Modules";

//lessons
import WordsCape from "./components/wordle-game/WordsCape";
import AltSpaceVR from "./components/lessons/AltSpaceVR";
import AltSpaceVRWS from "./components/lessons/AltSpaceVRWS";
import Lesson from "./components/lessons/Lesson";
import SkillsDetails from "./components/lessons/skills/SkillsDetails";
import SkillAssess from "./components/lessons/skills/SkillAssess";
import SkillSims from "./components/lessons/skills/SkillSims";
import SkillQuiz from "./components/lessons/skills/SkillQuiz";
import QuizResult from "./components/lessons/results/QuizResult";
import Rewards from "./components/lessons/results/Rewards";
import Loading from "./components/common/Loading";
import Subskills from "./components/lessons/skills/Subskills";

import Video from "./components/video/index";
import Planner from "./components/planner";

//react-query modules  and functions
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllData,
  newEnrollment,
  adminEnrolledData,
  getOrganizations,
  getAnalyticsData,
} from "./components/_api/index";
import { getNotifications } from './components/_api/getNotifications'

import {
  setData,
  setLoading,
  setOrganization,
  setNotifications,
  enableAnalyticsToComplition
} from "./redux-features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import PreviewCheck from "./components/home/PreviewCheck";

// import Sticky from "./components/sticky/Sticky";
import StickyDemo from "./components/sticky/StickyDemo";

import GotCode from "./components/auth/GotCode";
import ChangePassword from "./components/auth/Changepassword";
import Footer from "./components/common/Footer";

import { useLocation } from 'react-router-dom';
import { isStatic } from "./components/common/isStatic";





import SkillAssignment from "./components/common/SkillAssignment";
import QRScaned from "./components/lessons/details/qr-scaned";
function App() {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loading);
  const user = useSelector((state) => state?.user?.userData?.user);
  const applicationName = useSelector((state) => state?.user?.userData?.applicationName);
  const queryClient = useQueryClient();

  let userEmail = localStorage.getItem("userEmail")

  var url = new URL(window.location.href);
  var token = url?.searchParams.get("token");
  let access = url?.searchParams.get("access");
  let orgID = url?.searchParams.get("orgID");
  let showTimer = url?.searchParams.get("timer");

  if (showTimer == "Yes" || showTimer == "No") {
    sessionStorage.setItem("timer", showTimer)
  }

  if (token && access === "ok" && orgID) {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("access", access);

    sessionStorage.removeItem("view");
    sessionStorage.removeItem("skillSectionName");
    sessionStorage.removeItem("contentType");
    sessionStorage.removeItem("subSkillId");
    sessionStorage.removeItem("skillSectionID");
  } else {
    access = sessionStorage.getItem("access");
  }

  // const dataLoading = useSelector(state => state.user.loading)
  // const isError = useSelector(state => state.user.error)
  const { mutate: newEnroll } = useMutation(newEnrollment, {
    onSuccess: (response) => {
      dispatch(setLoading(true));
      queryClient.invalidateQueries("fetching-all-data");
    },
    onError: (error) => { },
  });

  /*----------------------------------------------------------------
      Fetching All Data
  -------------------------------------------------------------------*/
  const { isLoading: adminDataLoading } = useQuery(
    "fetching-all-data",
    () => adminEnrolledData(),
    {
      enabled: !isStatic && Boolean(access === "ok" && orgID),
      onSuccess: async (response) => {
        dispatch(setData(response));
        dispatch(setLoading(false));
      },
    }
  );

  const { isLoading: orgLoading, data: orgData } = useQuery(
    "get-organizations",
    getOrganizations,
    {
      enabled: !isStatic && isAuthenticated(localStorage.getItem("token")),
      onSuccess: async (response) => {
        dispatch(setOrganization(response?.data));
      },
    }
  );

  const { data: notificationData } = useQuery(["get-notifications", user?.organizationId], () => getNotifications(user?.organizationId), {
    enabled: !isStatic && isAuthenticated(localStorage.getItem("token")),
    onSuccess: (response) => {
      dispatch(setNotifications(response?.map(noti => noti.notification)));
    },
  })

  const { isLoading: dataLoading, data: allData } = useQuery(
    "fetching-all-data",
    getAllData,
    {
      enabled: isStatic || Boolean(access !== "ok"),
      onSuccess: async (response) => {
        dispatch(setData(response));
        dispatch(setLoading(false));
        dispatch(setOrganization(orgData?.data));
        if (isAuthenticated(localStorage.getItem("token"))) {
          if (response?.data[0] && !response?.data?.[0]?.isEnrolled && !isStatic) {
            newEnroll();
          } else {
            dispatch(setLoading(false));
          }
        }
      },
    }
  );
  const { isLoading: analyticsLoading, data: analyticsData } = useQuery(
    "fetching-analytics-data",
    () => getAnalyticsData(userEmail, user?.organizationId),
    {
      enabled: Boolean(userEmail && user?.organizationId),
    }
  );


  useEffect(() => {
    if (!analyticsLoading && !dataLoading && allData && analyticsData) {
      dispatch(enableAnalyticsToComplition({ analyticsData, allData }))
    }
  }, [analyticsData, analyticsLoading, dataLoading, allData])

  let clarityKey = process.env.REACT_APP_CLARITY_KEY
  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", clarityKey);
  }, [clarityKey])

  useEffect(() => {
    handleAdminPreview();
    AOS.init({
      offset: 100,
      delay: 100,
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    if (isStatic) {
      const staticRoutes = ['/', '/login', '/welcome', "/full-register", "/clever-auth", "/profile", "/get-started", "/learn-more"];
      if (staticRoutes.includes(pathname)) {
        navigate('/module');
      }
    }
  }, [pathname]);

  /*--------------------------------------------------------------------------------
      Handling previews when admin views, adds or edits content type from admin app
  ---------------------------------------------------------------------------------*/
  const handleAdminPreview = () => {
    const url = new URL(window.location.href);
    const view = url?.searchParams?.get("view");
    const subSkillId = url?.searchParams?.get("subSkillId");
    const skillSectionID = url?.searchParams?.get("skillSectionId");
    const skillSectionName = url?.searchParams?.get("skillSectionName");
    const contentType = url?.searchParams?.get("contentType");
    const adminToken = url?.searchParams?.get("adminToken");

    if (view && subSkillId && skillSectionID) {
      sessionStorage.setItem("view", view);
      sessionStorage.setItem("subSkillId", subSkillId);
      sessionStorage.setItem("skillSectionID", skillSectionID);
      sessionStorage.removeItem("skillSectionName");
      sessionStorage.removeItem("contentType");
      sessionStorage.removeItem("adminToken");
      sessionStorage.setItem("adminToken", adminToken);
      return;
    } else if (view && skillSectionName && contentType) {
      sessionStorage.setItem("view", view);
      sessionStorage.setItem("skillSectionName", skillSectionName);
      sessionStorage.setItem("contentType", contentType);
      sessionStorage.removeItem("subSkillId");
      sessionStorage.removeItem("skillSectionID");
      sessionStorage.setItem("adminToken", adminToken);
      return;
    } else if (view && subSkillId) {
      sessionStorage.setItem("view", view);
      sessionStorage.setItem("subSkillId", subSkillId);
      return;
    }
  };

  useEffect(() => {
    if (applicationName) {
      document.title = applicationName;
      document.querySelector('meta[name="description"]').setAttribute("content", `Welcome to ${applicationName}`);
    }
  }, [applicationName]);

  if (dataLoading || loading || adminDataLoading || orgLoading)
    return <Loading />;
  /*--------------------------------------------------------------------------------
      Main retun for routes
  ---------------------------------------------------------------------------------*/
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route exact path="/got-a-code" element={<><GotCode /></>} ></Route>
        <Route
          exact
          path="/full-register"
          element={
            <>
              <FullRegister />
            </>
          }
        ></Route>
        <Route
          exact
          path="/clever-auth"
          element={
            <>
              <CleverAuth />
            </>
          }
        ></Route>
        <Route
          exact
          path="/email-verification/:id"
          element={
            <>
              <EmailVerification />
            </>
          }
        ></Route>
        <Route
          exact
          path="/reset-password"
          element={
            <>
              <ResetPassword />
            </>
          }
        ></Route>
        <Route
          exact
          path="/change-password"
          element={
            <>
              <ChangePassword />
            </>
          }
        ></Route>
        <Route
          exact
          path="/login"
          element={
            <>
              <Login />
            </>
          }
        ></Route>

        {/* <Route exact path="/module" element={<><Module /></>} ></Route> */}
        <Route
          exact
          path="/module"
          element={
            <>
              <Modules />
              <Footer />
            </>
          }
        ></Route>

        <Route
          exact
          path="/profile"
          element={
            <>
              <Profile />
            </>
          }
        ></Route>
        <Route
          exact
          path="/"
          element={
            <>
              <PreviewCheck />
            </>
          }
        ></Route>
        <Route
          exact
          path="/get-started"
          element={
            <>
              <GetStarted />
            </>
          }
        ></Route>
        <Route
          exact
          path="/welcome"
          element={
            <>
              <Wellcome />
            </>
          }
        ></Route>
        <Route
          exact
          path="/learn-more"
          element={
            <>
              <LearnMore />
            </>
          }
        ></Route>

        <Route
          exact
          path="/turn-phone-v"
          element={
            <>
              <TurnPhoneV />
            </>
          }
        ></Route>
        <Route
          exact
          path="/turn-phone-h"
          element={
            <>
              <TurnPhoneH />
            </>
          }
        ></Route>
        <Route
          exact
          path="/customer-support"
          element={
            <>
              <CustomerSupport />
            </>
          }
        ></Route>
        <Route
          exact
          path="/help-desk"
          element={
            <>
              <HelpDesk />
            </>
          }
        ></Route>
        <Route
          exact
          path="/privacy-policy"
          element={
            <>
              <PrivacyPolicy />
            </>
          }
        ></Route>
        <Route
          exact
          path="/success-skills-intro"
          element={<SuccessSkillsIntro />}
        ></Route>
        <Route
          exact
          path="/success-skills-form"
          element={<SuccessSkillForm />}
        ></Route>
        <Route
          exact
          path="/success-skill-search/:jobID/:skillData"
          element={<SuccessSkillSearch />}
        ></Route>
        <Route
          exact
          path="/success-skill-list/:jobID/:skillData"
          element={<SuccessSkillList />}
        ></Route>
        <Route
          exact
          path="/success-skill-details/:id"
          element={<SuccessSkillDetails />}
        ></Route>
        <Route exact path="/error" element={<Error />}></Route>

        <Route
          exact
          path="/module-detail/:id"
          element={
            <>
              <BusinessFoundation />
            </>
          }
        ></Route>

        <Route exact path="/wordscape" element={<WordsCape />}></Route>
        <Route exact path="/altspace-vr" element={<AltSpaceVR />}></Route>
        <Route exact path="/altspace-vrws" element={<AltSpaceVRWS />}></Route>
        <Route exact path="/lesson" element={
          <>
            <SkillAssignment />
            <Lesson />
            <Footer pl={"100px"} pr={"50px"} />
          </>
        } />
        <Route exact path="/skills" element={
          <>
            <SkillAssignment />
            <Subskills />
          </>
        }></Route>
        <Route exact path="/skill-detail" element={
          <>
            <SkillAssignment />
            <SkillsDetails />
          </>
        }>

        </Route>
        <Route
          exact
          path="/skill-detail-assess"
          element={
            <>
              <SkillAssignment />
              <SkillAssess />
            </>
          }
        ></Route>
        <Route exact path="/skill-detail-sims" element={
          <>
            <SkillAssignment />
            <SkillSims />
          </>
        }></Route>
        <Route exact path="/skill-detail-quiz" element={<SkillQuiz />}></Route>
        <Route exact path="/quiz-result" element={<QuizResult />}></Route>
        <Route exact path="/rewards" element={<Rewards />}></Route>

        {/* <Route exact path="/newsubskill" element={<Skills />}></Route> */}
        {/* <Route exact path="/chatbot" element={<ChatBot />}></Route> */}
        <Route exact path="/video" element={<Video />}></Route>

        {/* <Route exact path="/sticky" element={<Sticky />}></Route> */}
        <Route exact path="/sticky-demo" element={<StickyDemo />}></Route>
        <Route exact path="/planner" element={<Planner />}></Route>
        <Route exact path="/qr-scaned" element={<QRScaned />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  );
}

export default App;
